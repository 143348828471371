import { Suspense, lazy, useEffect } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Provider as StoreProvider } from 'react-redux';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';

import { Client, Provider, cacheExchange, fetchExchange } from 'urql';

import store from '../State/store';
import { getApiUrl } from '../Utils/urls';
import { Loading } from './components/loading/Loading';
import { Theme } from './components/theme-provider/ThemeProvider';
import { WhatsNewModal } from './components/whatsNewModal/WhatsNewModal';
import { ErrorPage } from './pages/error/Error';
import { Layout } from './pages/layout/Layout';

const SignIn = lazy(() => import('../legacy/Components/Login/SignIn'));

const SignUp = lazy(() => import('../legacy/Components/Login/SignUp'));
const BookingPage = lazy(() => import('./pages/booking/BookingPage'));
const ContactUsPage = lazy(() => import('./pages/contactUs/ContactUsPage'));
const ListingsPage = lazy(() => import('./pages/listings/ListingsPage'));
const ListYourHomePage = lazy(() => import('./pages/listYourHome/ListYourHomePage'));
const ProfilePage = lazy(() => import('./pages/profile/ProfilePage'));
const Dashboard = lazy(() => import('./pages/dashboard/DashboardPage'));

export const AppV2 = ({ onLegacyClick }: { onLegacyClick: () => void }) => {
  const client = new Client({
    url: getApiUrl(),
    exchanges: [cacheExchange, fetchExchange],
    requestPolicy: 'network-only',
  });

  const showBetaModal = JSON.parse(sessionStorage.getItem('showBetaModal') || 'true');

  return (
    <ErrorBoundary
      FallbackComponent={() => <ErrorPage />}
      onReset={() => {
        window.location.reload();
      }}
    >
      <StoreProvider store={store}>
        <Provider value={client}>
          <Theme>
            <BrowserRouter>
              <Layout onLegacyClick={onLegacyClick}>
                <Routes>
                  <Route
                    path='/'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <ListingsPage />
                      </Suspense>
                    }
                  />
                  <Route path='*' element={<Navigate to='/' replace />} />
                  <Route
                    path='/listings'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <ListingsPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/dashboard'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <Dashboard />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/sign-up'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <SignUp />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/property/:propertyTitle?'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <BookingPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/contact-us'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <ContactUsPage />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/list-your-home'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <ListYourHomePage />
                      </Suspense>
                    }
                  />
                  <Route
                    path='/profile'
                    element={
                      <Suspense fallback={<Loading isLoading={true} />}>
                        <ProfilePage />
                      </Suspense>
                    }
                  />
                </Routes>
                <WhatsNewModal isOpen={showBetaModal} />
              </Layout>
            </BrowserRouter>
          </Theme>
        </Provider>
      </StoreProvider>
    </ErrorBoundary>
  );
};
