import { useState } from 'react';

import CloseIcon from '@mui/icons-material/Close';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';

export const WhatsNewModal = ({ isOpen = false }: { isOpen: boolean }) => {
  const [open, setOpen] = useState(isOpen);

  const handleClose = () => {
    setOpen(false);
    sessionStorage.setItem('showBetaModal', 'false');
  };

  return (
    <Dialog
      onClose={handleClose}
      aria-labelledby='what-is-new-dialog-title'
      open={open}
      fullWidth
    >
      <DialogTitle id='what-is-new-dialog-title'>What&apos;s New</DialogTitle>
      <IconButton
        aria-label='close'
        onClick={handleClose}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <CloseIcon />
      </IconButton>
      <DialogContent dividers>
        <Typography variant='overline' gutterBottom>
          Release 1.1 🎉
        </Typography>
        <Typography component='div' gutterBottom>
          While we work on our next big feature for native payments and automating booking
          requests, here are some small quality of life changes!
          <br />
          <br />
          <ul>
            <li>
              <Typography fontWeight='bold'>List Your Home</Typography>
              We&apos;re cleaning up a few things and adding some additional validations to
              ensure homes are properly listed for tenants!
            </li>
            <li>
              <Typography fontWeight='bold'>Booking</Typography>
              We&apos;re adding additional validation to make booking easier for renters and
              more accurate for homeowners.
            </li>
            <li>
              <Typography fontWeight='bold'>Search</Typography>
              We are including manual city entry for property searches.
            </li>

            <li>
              <Typography fontWeight='bold'>Dashboard</Typography>
              We&apos;re trying to improve the experience for homeowners and renters by
              allowing homeowners to modify more details about their listings, including image
              prioritization.
            </li>
          </ul>
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Got It!</Button>
      </DialogActions>
    </Dialog>
  );
};
